<template>
  <div class="">
    <no-ssr>
      <carousel
        :per-page="1"
        :mouse-drag="false"
        :navigation-enabled="true"
        :autoplay="true"
        :autoplay-timeout="4000"
        :autoplay-hover-pause="true"
        pagination-active-color="#000000"
        pagination-color="#9b9b9b"
        :pagination-padding="0"
        :pagination-size="6"
        :loop="true"
        :dots-labels="dotsLabels"
        navigation-next-label="<i class='carousel-icons next-icon'></i>"
        navigation-prev-label="<i class='carousel-icons prev-icon'></i>"
        ref="carousel"
        :speed="carouselTransitionSpeed"
        @pageChange="pageChange"
        class="carousel relative mx-auto my-0"
        :class="currentPage < 6 ? 'randco-active' : ''"
      >
        <slide class="flex-grow">
          <div class="md:flex w-full relative mx-auto my-0 text-left">
            <div class="">
              <h1 class="text-h1 uppercase">LBP Pro</h1>
              <h2 class="text-h3 uppercase mb-10">TAKE YOUR SALON TO THE NEXT LEVEL</h2>
              <div class="">
                <p class="mb-4">Let LBP help you increase your educational opportunities, your clients’ satisfaction
                  and your salon’s bottom line.</p>
                <p>Find out how we can partner to ensure your success.</p>
              </div>
              <div class="md:flex items-center mt-10">
                <div class="mt-2">
                  <span class="block text-base italic">Call us</span>
                  <a href="tel:+1-305-600-1305" class="text-white text-h4 block uppercase py-2 px-4 bg-black md:mr-5 flex items-center justify-between">
                    +1 (305) 600-1305 <i class="material-icons block text-base ml-2">phone</i>
                  </a>
                </div>
                <div class="mt-2">
                  <span class="block text-base italic">Have us call you</span>
                  <a href="#" @click="openModal" class="text-white text-h4 block uppercase py-2 px-4 bg-black flex items-center justify-between">
                    Request to be contacted <i class="material-icons block text-base ml-2">person</i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </slide>
      </carousel>
    </no-ssr>
    <modal-contact-us />
  </div>
</template>

<script>
import ModalContactUs from 'theme/pages/HomeTabs/ModalForm'
import Carousel from 'theme/components/theme/blocks/Home/Carousel/Carousel'
import NoSSR from 'vue-no-ssr'
import rootStore from '@vue-storefront/core/store'
import {mapGetters} from 'vuex'

export default {
  name: 'WelcomeNext',
  data () {
    return {
      currentPage: 0,
      carouselTransitionSpeed: 250,
      hideImageAtIndex: null,
      dotsLabels: ['Label 1', 'Label 2']
    }
  },
  components: {
    Carousel,
    'Slide': () => import('vue-carousel').then(Slider => Slider.Slide),
    'no-ssr': NoSSR,
    ModalContactUs
  },
  beforeRouteEnter (to, from, next) {
    if (rootStore.getters['user/isLoggedIn']) {
      next({ name: 'my-account' })
    }
    next()
  },
  mounted () {
    if (this.loggedIn) {
      this.$router.push({ name: 'my-account' })
    }
  },
  computed: {
    ...mapGetters({
      'loggedIn': 'user/isLoggedIn'
    })
  },
  methods: {
    pageChange (index) {
      this.currentPage = index
      this.hideImageAtIndex = null
    },
    openModal () {
      this.$bus.$emit('modal-show', 'modal-contact-us')
    },
    closeModal () {
      this.$bus.$emit('modal-hide', 'modal-contact-us')
    }
  }
}
</script>

<style scoped lang="scss">
.carousel {
  &::v-deep {
    .VueCarousel-slide {
      flex-grow: 1 !important;
    }
  }
}

.unstyled {
  width: 550px;
  margin: 0 auto;

  li {
    width: 250px;
    float: left;
    line-height: 32px;

    &:first-child {
      margin-right: 50px;
    }
  }

  .pro_cta {
    height: 60px;
    line-height: 60px;
    letter-spacing: 0.05em;
    padding: 0 20px 0 25px;
    transition: background-color .5s ease, border-color .5s ease, color .5s ease;

    i {
      line-height: 62px;
      right: 20px;
    }

    &:hover {
      @apply bg-brown-2 text-white;
      text-decoration: none;
    }
  }
}
</style>
