<template>
  <modal class="contact-us bg-center bg-no-repeat bg-cover" name="modal-contact-us">
    <h2 class="uppercase text-white contact-us__title font-black leading-lg mb-30px text-center">
      {{ $t('Have us contact you') }}
    </h2>
    <p class="contact-us__subtitle font-serif font-normal text-2h6 text-white text-center italic">
      Need help setting up your LBP Pro account or want more information <br>
      about carrying our brands? Complete the form below.
    </p>
    <div class="form">
      <form class="flex flex-col md:flex-row form__wrap">
        <div class="w-full md:w-1/2 pr-7">
          <base-input
            class="mb-sm relative form__input"
            :placeholder="$t('Your Name')"
          />
          <base-input
            class="mb-sm relative form__input"
            :placeholder="$t('Business Name')"
          />
          <base-input
            class="mb-sm relative form__input"
            :placeholder="$t('Email')"
          />
          <base-input
            class="mb-sm relative form__input"
            :placeholder="$t('Phone')"
          />
          <base-textarea
            class="mb-sm relative form__input"
            :value="''"
            :placeholder="$t('Comments (optional)')"
          />
        </div>
        <div class="w-full md:w-1/2 pl-7">
          <span class="text-white mb-3 text-above-md block">{{ $t('Are you an existing customer?') }}</span>
          <div class="flex pb-xl">
            <base-radiobutton
              class="form__radio"
              :id="'existing-customer-y'"
              :value="existingCustomer === true"
              @click.native="existingCustomer = true"
            >
              {{ $t('Yes') }}
            </base-radiobutton>
            <base-radiobutton
              class="form__radio"
              :id="'existing-customer-n'"
              :value="existingCustomer === false"
              @click.native="existingCustomer = false"
            >
              {{ $t('No') }}
            </base-radiobutton>
          </div>
          <transition
            name="accordion"
            @before-enter="beforeEnter"
            @enter="enter"
            @before-leave="beforeLeave"
            @leave="leave"
          >
            <div v-if="existingCustomer" class="animate-block">
              <span class="text-white mb-3 text-above-md block">{{ $t('Which of our brands do you already carry?') }}</span>
              <div class="flex pb-xl">
                <div class="flex w-1/2 items-start flex-col justify-center">
                  <base-checkbox
                    class="form__checkbox"
                    id="brandsRCo"
                    v-model="brandsRCo"
                    @click="brandsRCo = !brandsRCo"
                  >
                    {{ $t('R+Co') }}
                  </base-checkbox>
<!--                  <base-checkbox
                    class="form__checkbox"
                    id="brandsSmithCult"
                    v-model="brandsSmithCult"
                    @click="brandsSmithCult = !brandsSmithCult"
                  >
                    {{ $t('Smith & Cult') }}
                  </base-checkbox>-->
                  <base-checkbox
                    class="form__checkbox"
                    id="brandsEducation"
                    v-model="brandsEducation"
                    @click="brandsEducation = !brandsEducation"
                  >
                    {{ $t('Education') }}
                  </base-checkbox>
                </div>
                <div class="flex w-1/2 items-center justify-start">
                  <base-checkbox
                    class="form__checkbox"
                    id="brandsVaughn"
                    v-model="brandsVaughn"
                    @click="brandsVaughn = !brandsVaughn"
                  >
                    {{ $t('V76 by Vaughn') }}
                  </base-checkbox>
                </div>
              </div>
            </div>
          </transition>

          <span class="text-white mb-3 text-above-md block">{{ $t('Which of our brands are you interested in?') }}</span>
          <div class="flex mb-5 border-b border-grey-28 border-dotted">
            <base-checkbox
              class="form__checkbox"
              id="brandsAll"
              v-model="brandsAll"
              @click="brandsAll = !brandsAll"
            >
              {{ $t('All brands') }}
            </base-checkbox>
          </div>
          <div class="flex pb-xl">
            <div class="flex w-1/2 items-start flex-col justify-center">
              <base-checkbox
                class="form__checkbox"
                id="brandsRCoA"
                v-model="brandsRCoA"
                @click="brandsRCoA = !brandsRCoA"
              >
                {{ $t('R+Co') }}
              </base-checkbox>
<!--              <base-checkbox
                class="form__checkbox"
                id="brandsSmithCultA"
                v-model="brandsSmithCultA"
                @click="brandsSmithCultA = !brandsSmithCultA"
              >
                {{ $t('Smith & Cult') }}
              </base-checkbox>-->
              <base-checkbox
                class="form__checkbox"
                id="brandsEducationA"
                v-model="brandsEducationA"
                @click="brandsEducationA = !brandsEducationA"
              >
                {{ $t('Education') }}
              </base-checkbox>
              <base-checkbox
                class="form__checkbox"
                id="brandsVaughnA"
                v-model="brandsVaughnA"
                @click="brandsVaughnA = !brandsVaughnA"
              >
                {{ $t('V76 by Vaughn') }}
              </base-checkbox>
            </div>
          </div>
          <button-full @click.native="closeModal" :title="$t('Submit')" class="form__submit mb-5 relative w-full border-brown-2 text-brown-2 border">
            {{ $t('Submit') }}
            <i class="ss-gizmo ss-right absolute top-0 text-h5"></i>
          </button-full>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import BaseTextarea from 'theme/components/core/blocks/Form/BaseTextarea'
import BaseRadiobutton from 'theme/components/core/blocks/Form/BaseRadiobutton'
import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import Modal from 'theme/components/core/Modal'
import ButtonFull from 'theme/components/theme/ButtonFull'
import SlideAnimation from 'theme/mixins/animate/slide'

export default {
  name: 'ModalContactUs',
  mixins: [SlideAnimation],
  data () {
    return {
      existingCustomer: false,
      brandsRCo: false,
      brandsSmithCult: false,
      brandsEducation: false,
      brandsVaughn: false,
      brandsAll: false,
      brandsRCoA: false,
      brandsSmithCultA: false,
      brandsEducationA: false,
      brandsVaughnA: false,
    }
  },
  components: {
    Modal,
    BaseInput,
    BaseTextarea,
    BaseRadiobutton,
    BaseCheckbox,
    ButtonFull
  },
  methods: {
    closeModal () {
      this.$bus.$emit('modal-hide-contact-us')
    }
  }
}
</script>
<style lang="scss" scoped>
  .contact-us {
    &::v-deep {
      .modal .modal-container {

      }
    }
    background-image: url("/assets/Auth/welcome-hero-04.jpg");
    background-size: cover;
    &:before {
      @apply h-full absolute w-full left-0 top-0;
      background-color: rgba(0, 0, 0, .4);
      content: '';
    }
    &:after {
      @apply h-full absolute w-full left-0 top-0 bg-center bg-repeat;
      background-image: url("/assets/Auth/pattern-img-tile.png");
      content: '';
    }
    &__title {
      font-size: 50px;
      letter-spacing: .28em;
    }
    &__subtitle {
      line-height: 32px;
      margin-bottom: 35px;
    }
    &::v-deep {
      .modal-container {
        @apply w-full;
        &:after {
          @apply hidden;
        }
        &:before {
          @apply hidden;
        }
      }
      .modal-close {
        @apply text-white z-2 border-white;
        top: 20px;
        &:hover {
          @apply text-brown-2 border-brown-2;
        }
      }
      .modal-center {
        @apply flex h-full pt-5 relative z-1;
      }
    }
  }

  .form {
    &__wrap {
      max-width: 700px;
      margin: 0 auto;
    }
    &__input {
      &::v-deep {
        input {
          @apply text-above-md leading-sm bg-grey-29;
          border: none;
        }
      }
    }
    &__input {
      &::v-deep {
        textarea {
          @apply text-above-md leading-sm bg-grey-29;
          border: none;
          height: 200px;
          resize: none;
        }
      }
    }
    &__radio {
      margin-right: 25px;
      &::v-deep {
        label {
          @apply text-white text-above-md;
          margin-left: 8px;
        }
      }
    }
    &__checkbox {
      margin-bottom: 18px;
      &::v-deep {
        label {
          @apply text-white text-above-md;
          margin-left: 8px;
        }
      }
    }
    &__submit {
      height: 60px;
      line-height: 60px;
      padding: 0 40px 0 20px;
      text-align: left;
      transition: background-color .5s ease, border-color .5s ease, color .5s ease;
      .ss-gizmo {
        line-height: 62px;
        right: 20px;
      }
      &:hover {
        @apply bg-white border-black text-black;
      }
    }
  }
  .animate-block {
    overflow: hidden;
    transition: .25s ease-out;
  }
</style>
