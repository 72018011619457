<template>
  <div v-if="!$isServer">
    <p>Clearing Cache...</p>
    <button @click="window.location = window.location.origin">Continue</button>
  </div>
</template>

<script>
export default {
  name: 'ClearCache',
  mounted () {
    if (this.$isServer) {
      return
    }

    console.log('clearing...')
    window.navigator.serviceWorker.getRegistrations()
      .then(registrations => {
        registrations.forEach(registration => {
          registration.unregister()
          console.log(registration)
          window.setTimeout(() => {
            window.location = window.location.origin
          }, 1000)
        })
      })
  }
}
</script>

<style scoped>

</style>
