<template>
  <div>
    <div class="relative">
      <input
        class="m0 no-outline"
        type="radio"
        :id="id"
        :checked="value"
        @keyup.enter="$emit('click')"
        @click="$emit('click')"
        @blur="$emit('blur')"
        @change="$emit('change')"
        :disabled="disabled"
      >
      <label
        class="pl-7 label capitalize text-black text-md tracking-xs leading-sm whitespace-no-wrap cursor-pointer select-none"
        :for="id"
      >
        <slot />
      </label>
    </div>
    <template v-if="validation">
      <span
        class="block cl-error h6"
        v-if="validation.condition"
      >
        {{ validation.text }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BaseRadiobutton',
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    validation: {
      type: Object,
      required: false,
      default: () => { }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  label {
    &:before {
      @apply bg-grey-6;
      box-shadow: inset 1px 1px 1px rgba(0, 0, 0, .25);
      border-radius: 50%;
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      width: 20px;
      height: 20px;
      cursor: pointer;
      box-sizing: border-box;
    }
  }

  input {
    display: none;

    &:checked + label {
      &:before {
        @apply bg-brown-2;
      }

      &:after {
        @apply absolute rounded-50 bg-white;
        content: '';
        top: 9px;
        left: 6px;
        width: 8px;
        height: 8px;
      }
    }

    &:disabled + label {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;

      &:hover,
      &:focus {
        &:before {
          cursor: not-allowed;
        }
      }
    }
  }
</style>
